import React, { Component } from "react";

import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { connect } from "react-redux";

let typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "reg",
  "wej",
  "satel_wej",
];

class IconBadge extends Component {
  state = {
    numberOfActives: 0,
  };

  componentDidMount() {
    let numberOfActives = this.numberOfActivesForGroups(this.props.devicesStates);
    if (numberOfActives != this.state.numberOfActives) {
      this.setState({ numberOfActives });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.currentRoute === "Places" || nextProps.currentRoute === "Places" || nextProps.globalSettings.get("sideMenuShown")) {
      let numberOfActives = this.numberOfActivesForGroups(nextProps.devicesStates);
      if (numberOfActives != this.state.numberOfActives || this.props.isBig != nextProps.isBig) {
        this.setState({ numberOfActives });
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  numberOfActivesForGroups = (devicesStates) => {
    const { groups, group, group_devices, params_devices, devices } = this.props;
    let list = group ? [group.get("id")] : null;
    let arrayOfIds = [];
    if (list) {
      groups &&
        groups.map((el) => {
          if (el && el.get("id_rodzica") == group.get("id")) {
            list.push(el.get("id"));
          }
        });

      for (let index = 0; index < group_devices.size; index++) {
        if (group_devices.get(index) && list.includes(group_devices.get(index).get("id_grupy"))) {
          let deviceId = group_devices.get(index).get("id_obiektu");
          if (devices.get(deviceId)) {
            let typKomponentu = devices.get(deviceId).get("typ_komponentu");

            if (typKomponentu == "custom") {
              let powiazane = params_devices.get(deviceId) ? params_devices.get(deviceId).get("powiazane") : "";

              let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

              powiazaneArrayWithPossibleCustom.map((el, index) => {
                el = el.split(":").reverse();
                let deviceFromCustom = devices.get(el[0]);
                if (deviceFromCustom) {
                  let deviceFromCustomId = deviceFromCustom.get("id");
                  let stanFromCustom = devicesStates.get(deviceFromCustomId) ? devicesStates.get(deviceFromCustomId).get("state") : 0;

                  if (
                    !arrayOfIds.includes(deviceFromCustomId) &&
                    stanFromCustom != 0 &&
                    typeToShow.includes(deviceFromCustom.get("typ_komponentu")) &&
                    params_devices &&
                    params_devices.get(deviceFromCustomId) &&
                    (params_devices.get(deviceFromCustomId).get("params") & 0b1) > 0
                  ) {
                    arrayOfIds.push(deviceFromCustomId);
                  }
                }
              });
            }

            if (devicesStates.get(deviceId)) {
              let stan = devicesStates.get(deviceId).get("state");

              if (typKomponentu === "ip_radio" && devicesStates.get(deviceId).get("status")) {
                if (devicesStates.get(deviceId).get("status").includes("play")) {
                  stan = 1;
                } else {
                  stan = 0;
                }
              } else if (typKomponentu === "ledww") {
                stan = stan & 0xff;
              }

              if (
                !arrayOfIds.includes(deviceId) &&
                stan != undefined &&
                stan != 0 &&
                typeToShow.includes(typKomponentu) &&
                params_devices &&
                params_devices.get(deviceId) &&
                (params_devices.get(deviceId).get("params") & 0b1) > 0
              ) {
                arrayOfIds.push(deviceId);
              }
            }
          }
        }
      }
    }
    return arrayOfIds.length;
  };

  render() {
    const { children, isBig } = this.props;

    const { numberOfActives } = this.state;

    return (
      <View>
        {children}
        {numberOfActives != 0 && (
          <View
            style={{
              position: "absolute",
              top: isBig ? -10 : -5,
              right: isBig ? -15 : -8,
              minWidth: isBig ? 24 : 15,
              height: isBig ? 24 : 15,
              borderRadius: isBig ? 24 : 15,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FF0000",
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: isBig ? 16 : 10,
              }}
            >
              {numberOfActives}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("groups"),
    group_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("group_devices"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
    devicesStates: state.statesData.get("statesActive"),
    currentRoute: state.statesData.get("currentRoute"),
    globalSettings: state.globalSettings,
  };
};

export default connect(mapStateToProps)(IconBadge);
