import React, { Component } from "react";
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";
import mqttClient from "../../../services/mqtt";

import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import { t } from "../../../services/i18n";
import {
  ComponentHeaderText,
  StyledIcon,
  SwitchStyled,
  SliderStyled,
  ComponentContainer,
  SecondaryColorText,
  PowerStyled,
  PrimaryColorText,
} from "../../UI/styledComponents";
import * as Haptics from "expo-haptics";
import { connect } from "react-redux";
import { addFavouriteColorToRGBModal, removeFavouriteColorFromRGBModal } from "../../../store/actions";
import { Slider } from "@miblanchard/react-native-slider";
import { SliderWW } from "../../UI/styledComponents";
import { kelvinToRgb } from "./LedWWComponent";
import { formatDistanceToNow } from "date-fns";
import * as Localization from "expo-localization";
import { ar, cs, de, es, fr, it, pl, pt } from "date-fns/locale";
import ButtonsSelector from "../../UI/ButtonsSelector";
import PickerSelect from "../../UI/PickerSelect";
const locales = { ar, cs, de, es, fr, it, pl, pt };
let lang = Localization.locale.split("-")[0];

let changeHexToPercent = (hexValue) => {
  let percentValue = (hexValue * 100) / 255;
  if (percentValue < 1) {
    percentValue = Math.floor(percentValue * 10) / 10;
  } else {
    percentValue = Math.round(percentValue);
  }

  return `${percentValue} %`;
};

let changePercentToHex = (percentValue) => {
  let hexValue = Math.round((percentValue * 255) / 100);
  return hexValue;
};

class LedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockProps: false,
      value: 0,
      deviceFromScene: false,
      switchValue: false,
      pickerValue: 0,
      desc: null,
      res: null,
      recValue: 0,
    };
    this.modes = [
      {
        label: "0.3%",
        value: 1,
      },
      {
        label: "25%",
        value: 64,
      },
      {
        label: "50%",
        value: 128,
      },
      {
        label: "75%",
        value: 196,
      },
      {
        label: "100%",
        value: 255,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        value: Number(nextProps.deviceState.get("state")),
        switchValue: nextProps.deviceState.get("state") == 0 ? false : true,
        desc: nextProps.deviceState.get("desc"),
        res: nextProps.deviceState.get("res"),
        recValue: Number(nextProps.deviceState.get("state")),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        value: Number(nextProps.deviceInScene.get("value")),
        switchValue: nextProps.deviceInScene.get("value") == 0 ? false : true,
        desc: nextProps.deviceInScene.get("desc"),
        res: nextProps.deviceInScene.get("res"),
        recValue: Number(nextProps.deviceInScene.get("value")),
      };
    }
    if (!nextProps.deviceInScene && nextProps.deviceState) {
      return {
        desc: nextProps.deviceState.get("desc") ? nextProps.deviceState.get("desc") : null,
        res: nextProps.deviceState.get("res") ? nextProps.deviceState.get("res") : null,
        recValue: Number(nextProps.deviceState.get("state")),
      };
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.value != nextState.value ||
      this.state.desc != nextState.desc ||
      this.state.res != nextState.res ||
      this.state.recValue != nextState.recValue
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
  }

  increaseOne = () => {
    const { device, params_device } = this.props;
    let step = 1;
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      step = device.get("param1");
      customRange = true;
    }
    const { value } = this.state;
    let newValue = value;

    if (!customRange) {
      if (value >= 0 && value <= 3) {
        newValue = value + 1;
      } else if (value > 3 && value < 254) {
        newValue = value + changePercentToHex(1);
      }
    } else {
      if (value >= device.get("min") && value < device.get("max")) {
        newValue = value + step;
      } else if (value < device.get("min")) {
        newValue = device.get("min");
      }
    }
    newValue = parseFloat(newValue.toFixed(2));
    this.setState(
      {
        value: newValue,
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  decreaseOne = () => {
    const { device, params_device } = this.props;
    let step = 1;
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      step = device.get("param1");
      customRange = true;
    }
    const { value } = this.state;
    let newValue = value;

    if (!customRange) {
      if (value > 0 && value <= 4) {
        newValue = value - 1;
      } else if (value > 4 && value <= 255) {
        newValue = value - changePercentToHex(1);
      }
    } else {
      if (value > device.get("min") && value <= device.get("max")) {
        newValue = value - step;
      }
      if (value == device.get("min")) {
        newValue = 0;
      }
    }
    newValue = parseFloat(newValue.toFixed(2));
    this.setState(
      {
        value: newValue,
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  toggleSwitchValue = () => {
    const { device, params_device } = this.props;
    let value = this.state.switchValue ? 0 : 255;
    let switchValue = this.state.switchValue ? false : true;

    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
      value = this.state.switchValue ? 0 : device.get("max");
    }

    const { favouritesColors } = this.props;
    if (value == 0) {
      const { device } = this.props;
      const deviceID = device.get("id");
      this.props.onAddFavouriteColorToRGBModal(deviceID, "lastColor", this.state.value);
    } else {
      let colorOn = favouritesColors ? favouritesColors.get("lastColor") : undefined;

      if (colorOn && colorOn.get("color") !== undefined) {
        value = colorOn.get("color");
      }
    }

    this.setState(
      {
        switchValue,
        value,
        blockProps: true,
      },
      () => {
        this.sendMessage(value);
      }
    );
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (value) => {
    const { params_device } = this.props;

    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.sendMessage(value, true);
  };

  onSlidingCompleteHandler = (value) => {
    this.setState({ value, switchValue: value != 0 ? true : false }, () => this.sendMessage(value));
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
        switchValue: Number(deviceState.get("state")) != 0 ? true : false,
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  //clearing old timeout and starting new one, to have always 5 sec
  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, fromSliderMoving) => {
    const { device, deviceInScene, changeScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    if (deviceInScene) {
      let infoToUpdate = deviceInScene.set("value", value);
      changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }

    let message = `/api/set/${this.props.device.get("id")}/setValue/${value}`;
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  onModeClose = (value) => {
    this.sendMessage(value);
  };

  render() {
    const {
      device,
      header,
      readOnly,
      deviceInScene,
      deviceFromCustom,
      params_device,
      group_params,
      dimensions,
      deviceState,
      customWidth,
      icons,
      deviceFromSketch,
      deviceFromAutomatization,
    } = this.props;

    let sliderMinValue = 0;
    let sliderMaxValue = 255;
    let customRange = false;

    let hideValue = false;

    if (params_device && (params_device.get("params") & 0x2000000) > 0) {
      hideValue = true;
    }

    const rowValues = device.get("uwagi").split(";");

    const pickerValues = [];

    for (let index = 0; index < rowValues.length; index++) {
      const columnValues = rowValues[index].split("=");
      if (columnValues.length > 4) {
        if (columnValues[2] == "1") {
          // znaczy ze rowna sie
          const icon = icons.get(columnValues[3]) ? icons.get(columnValues[3]).get("wartoscB") : undefined;

          pickerValues.push({ label: columnValues[1], value: Number(columnValues[0]), iconBase: icon });
        }
      }
    }

    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0) ||
      customWidth
    ) {
      customRange = true;
      sliderMinValue = device.get("min");
      sliderMaxValue = device.get("max");
    }

    let imageAndDesc = params_device && (params_device.get("params") & 0x40000) > 0;
    let imageAndValue = params_device && (params_device.get("params") & 0x80000) > 0;

    let evaluated = !customRange
      ? changeHexToPercent(this.state.recValue)
      : this.state.desc
      ? this.state.desc
      : this.state.recValue + params_device.get("url");

    if (imageAndDesc && imageAndValue && this.state.desc) {
      if (!customRange) {
        evaluated = evaluated + " " + changeHexToPercent(this.state.recValue);
      } else {
        evaluated = evaluated + " " + this.state.recValue + params_device.get("url");
      }
    }

    let { value, switchValue } = this.state;
    let showSwitch = (params_device && (params_device.get("params") & 0x8000) > 0) || this.state.res;
    let hidePower = customRange && !showSwitch;
    let showIcons = false;
    let iconOnlyOnSketch = group_params && (group_params & 0x04) > 0 ? true : false;
    if (
      (device.has("ikona_0") && (device.get("ikona_0") !== null || device.get("ikona_1") !== null)) ||
      (device.has("ikona0") && (device.get("ikona0") !== null || device.get("ikona1") !== null))
    ) {
      if (!iconOnlyOnSketch) {
        showIcons = true;
      }
    }

    let switchContent = this.state.res ? (
      <ImageForLinearInput id={this.state.res} deviceFromCustom={deviceFromCustom} />
    ) : showIcons ? (
      <TouchableOpacity onPress={this.toggleSwitchValue} disabled={readOnly} hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}>
        <ImageFromIkona0_0 device={device} value={value} />
      </TouchableOpacity>
    ) : this.props.detailContent ? (
      <PowerStyled value={switchValue} onPress={this.toggleSwitchValue} disabled={readOnly} />
    ) : (
      <SwitchStyled style={{ marginLeft: 10 }} value={switchValue} onValueChange={this.toggleSwitchValue} disabled={readOnly} />
    );
    let valuePicker = null;

    let listView = !this.props.detailContent && !dimensions.get("smallTiles");

    if (pickerValues.length > 0) {
      valuePicker =
        pickerValues.length > 8 || deviceFromCustom || deviceInScene || listView ? (
          <View style={{ alignItems: "center", flexDirection: "row", marginLeft: 10 }}>
            {!listView && <ComponentHeaderText>{t("CHOOSE_OPTION")}</ComponentHeaderText>}
            <PickerSelect
              items={pickerValues}
              containerStyle={listView ? { width: 120 } : { width: "50%" }}
              onFinalChange={(value) => this.sendMessage(value)}
              onValueChange={(value) => {
                this.setState({ value: value, blockProps: true });
              }}
              value={this.state.value}
            />
          </View>
        ) : pickerValues.length > 1 ? (
          <ComponentContainer numOfColumns={1}>
            <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 0 } : { opacity: 1, width: "100%", paddingLeft: 0 }}>
              <ComponentHeaderText style={{ marginBottom: 15 }}>{t("CHOOSE_OPTION")}</ComponentHeaderText>
              <View flexDirection="row" justifyContent={"space-between"} tyle={{ marginBottom: 10 }} alignItems="center">
                <ButtonsSelector
                  active={Number(this.state.value)}
                  onChangeTab={(value) => {
                    this.sendMessage(value);
                    this.setState({ value: value });
                  }}
                  tabs={pickerValues}
                  fontSize={12}
                  iconSize={24}
                  checkByValue={true}
                />
              </View>
            </View>
          </ComponentContainer>
        ) : null;
    }

    return this.props.detailContent ? (
      <View flexDirection="column">
        {!readOnly && !valuePicker && !hidePower && (
          <ComponentContainer numOfColumns={1}>
            <View
              flexDirection="row"
              justifyContent={"space-between"}
              style={{ height: 50, paddingLeft: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
              alignItems="center"
            >
              <ComponentHeaderText>{t("POWER")}</ComponentHeaderText>
              {switchContent}
            </View>
          </ComponentContainer>
        )}
        {!readOnly && !valuePicker && (
          <ComponentContainer numOfColumns={1}>
            <View alignItems="center">
              <ButtonsSelector
                fontSize={14}
                onChangeTab={(value) => {
                  if (customRange) {
                    const percent = this.modes[value].value / 255;
                    this.onSlidingCompleteHandler(percent * (sliderMaxValue - sliderMinValue) + sliderMinValue);
                  } else {
                    this.onSlidingCompleteHandler(this.modes[value].value);
                  }
                }}
                style={styles.tabSelector}
                tabs={device.get("typ_komponentu") === "ac" ? this.modesAc : this.modes}
              />
            </View>
          </ComponentContainer>
        )}
        {valuePicker && <ComponentContainer numOfColumns={1}>{valuePicker}</ComponentContainer>}
        {!valuePicker && (
          <ComponentContainer numOfColumns={1}>
            <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
              <View
                flexDirection="row"
                justifyContent="space-between"
                style={{ marginBottom: 10, flexDirection: "row", justifyContent: "space-between" }}
              >
                <ComponentHeaderText>{t("VALUE")}</ComponentHeaderText>
                <ComponentHeaderText>{!customRange ? changeHexToPercent(this.state.recValue & 0xff) : this.state.recValue}</ComponentHeaderText>
              </View>
              <Slider
                animateTransitions
                value={value & 0xff}
                minimumValue={sliderMinValue}
                maximumValue={sliderMaxValue}
                onValueChange={(value) => this.onSlidingChangeHandler(value[0])}
                onSlidingComplete={(value) => this.onSlidingCompleteHandler(value[0])}
                disabled={readOnly}
                step={1}
                thumbStyle={{
                  backgroundColor: kelvinToRgb(2700),
                  borderRadius: 20,
                  height: 40,
                  width: 40,
                  borderWidth: 2,
                  borderColor: "white",
                }}
                minimumTrackStyle={{
                  borderRadius: 15,
                  height: 25,
                  backgroundColor: kelvinToRgb(2700),
                }}
                maximumTrackStyle={{
                  borderRadius: 15,
                  height: 25,
                }}
              />
            </View>
          </ComponentContainer>
        )}
      </View>
    ) : (
      <View style={{ width: "100%" }}>
        {
          <View
            style={{
              flexDirection: "row",
              justifyContent: deviceFromCustom ? "center" : "space-between",
              alignItems: "center",
              marginBottom: readOnly || dimensions.get("smallTiles") ? 0 : 10,
            }}
          >
            {!deviceFromCustom && (
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {!deviceFromCustom ? header : null}
                {evaluated && !customWidth && !deviceFromAutomatization && !deviceFromCustom && dimensions.get("smallTiles") && !hideValue && (
                  <PrimaryColorText style={{ paddingLeft: 8, fontSize: 12 }}>{evaluated}</PrimaryColorText>
                )}
              </View>
            )}
            {!hideValue || showSwitch || dimensions.get("smallTiles") ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: dimensions.get("smallTiles") && !deviceFromCustom ? 0 : 5,
                  height: dimensions.get("smallTiles") && !deviceFromCustom && !deviceFromSketch ? 40 : undefined,
                }}
              >
                {!hideValue && (!dimensions.get("smallTiles") || customWidth) && !valuePicker && (
                  <ComponentHeaderText style={{}}>
                    {!customRange
                      ? changeHexToPercent(this.state.recValue)
                      : this.state.desc
                      ? this.state.desc
                      : this.state.recValue + params_device.get("url")}
                  </ComponentHeaderText>
                )}
                {!hideValue && (!dimensions.get("smallTiles") || customWidth) && valuePicker}
                {deviceFromCustom ? valuePicker : null}
                {deviceFromCustom && evaluated ? (
                  <PrimaryColorText style={{ paddingLeft: 8, fontSize: 12, paddingTop: 5, paddingRight: 5 }}>{evaluated}</PrimaryColorText>
                ) : null}
                {(showSwitch || showIcons || (dimensions.get("smallTiles") && device.get("typ_komponentu") == "led")) && !valuePicker
                  ? switchContent
                  : valuePicker && this.state.res
                  ? switchContent
                  : null}
              </View>
            ) : null}
          </View>
        }
        {!readOnly &&
        (!dimensions.get("smallTiles") || deviceFromCustom || customWidth || deviceFromAutomatization || customRange) &&
        !valuePicker ? (
          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                alignContent: "center",
              }}
            >
              <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.decreaseOne}>
                <StyledIcon name={"minus-circle"} color={"primary"} size={deviceFromCustom ? 18 : 24} />
              </TouchableOpacity>

              <SliderStyled
                value={value}
                minimumValue={sliderMinValue}
                maximumValue={sliderMaxValue}
                // onSlidingStart={this.onSlidingStartHandler}
                onValueChange={this.onSlidingChangeHandler}
                onSlidingComplete={this.onSlidingCompleteHandler}
                disabled={readOnly}
              />
              <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.increaseOne}>
                <StyledIcon name={"plus-circle"} size={deviceFromCustom ? 18 : 24} />
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  sliderContainer: {
    height: 300, // Adjust to your need
    justifyContent: "center",
  },
  slider: {
    height: "100%", // This will make the Slider vertical
    width: 300,
  },
});

const mapStateToProps = (state, ownProps) => {
  let favourites = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("favouritesColors");
  return {
    favouritesColors: favourites ? favourites.get(ownProps.device.get("id").toString()) : null,
    dimensions: state.statesData.get("dimensions"),
    icons: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("icons"),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddFavouriteColorToRGBModal: (deviceID, colorPosition, color) => dispatch(addFavouriteColorToRGBModal(deviceID, colorPosition, color)),
    onRemoveFavouriteColorFromRGBModal: (deviceID, colorPosition) => dispatch(removeFavouriteColorFromRGBModal(deviceID, colorPosition)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LedComponent);
