import React, { useState, useEffect } from "react";
import { Text, TouchableOpacity, Platform, View, VirtualizedList, ScrollView } from "react-native";
import { connect } from "react-redux";
import { ComponentContainer, ComponentHeaderText, PrimaryColorText, SecondaryColorText } from "../components/UI/styledComponents";
import { t } from "../services/i18n";
import { ScreenView } from "../components/UI/screenViewX";
import { StyledIcon } from "../components/UI/styledComponents";
import mqttClient from "../services/mqtt";

import { addDeviceToFavourites, removeDeviceFromFavourites, changeParamsOfDevice, changeName, changeObjectSize } from "../store/actions";
import { useTheme } from "../../ThemeContext";
import ColorPicker from "../components/smartHomeComponents/rgb/RGBModal/ColorPicker";

import { DeviceListHandler } from "../utils";
import { formatDistanceToNow } from "date-fns";
import * as Localization from "expo-localization";
import { ar, cs, de, es, fr, it, pl, pt } from "date-fns/locale";
import ButtonsSelector from "../components/UI/ButtonsSelector";
import DeviceTimeControlDialog from "../utils/DeviceTimeControlDialog";
import DeviceChangePinDialog from "../utils/DeviceChangePinDialog";
import ModalSimple from "../components/UI/ModalSimple";
import { typesShowOnFullWith } from "../utils/DevicesToShowByColumns";
import { Device } from "./Account/Statistics/Logbook_AccountScreen";
import Immutable from "immutable";
const locales = { ar, cs, de, es, fr, it, pl, pt };
let lang = Localization.locale.split("-")[0];

const componentsToHideLastChange = ["energy"];
const componentToShowHistory = [
  "przekaznik",
  "flaga",
  "led",
  "rgb",
  "rgbw",
  "rgbww",
  "ledww",
  "roleta_procenty",
  "roleta_lamelki",
  "satel_alarm",
  "wej",
  "ip_radio",
  "radio",
];

const THRESHOLD = 1e20; // Set a reasonable threshold for extreme values

const DeviceHistory = ({ logbookData, device }) => {
  const filterData = (data) => {
    const filteredData = [];

    for (let i = 0; i < data.size; i++) {
      const currentItem = data.get(i);
      const nextItem = data.get(i + 1);
      const currentValue = currentItem.get("wartosc");
      if (Math.abs(currentValue) < THRESHOLD) {
        // Always include the current item if it's the last one or if the next item's value is different
        if (!nextItem || currentItem.get("wartosc") !== nextItem.get("wartosc")) {
          filteredData.push(currentItem);
        }
      }
    }

    return Immutable.List(filteredData);
  };

  return logbookData && logbookData.size ? (
    <ComponentContainer numOfColumns={1}>
      <ScrollView style={{ maxHeight: 200 }}>
        <VirtualizedList
          data={filterData(
            logbookData.sort((l1, l2) =>
              l1.get("czas") < l2.get("czas") ? 1 : l1.get("czas") === l2.get("czas") && l1.get("id") < l2.get("id") ? 1 : -1
            )
          )}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={true}
          getItem={(data, index) => data.get(index)}
          getItemCount={(data) => data.size}
          scrollEnabled={false}
          renderItem={({ item, index }) => (
            <Device item={item} deviceName={device.get("opis_menu")} deviceType={device.get("typ_komponentu")} fromDetails />
          )}
          keyExtractor={(item, index) => `${item.get("id_obiektu")} - ${index}`}
        />
      </ScrollView>
    </ComponentContainer>
  ) : null;
};

const DeviceDetailsScreen = (props) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const [objectOptions, setObjectOptions] = useState([]);

  const [showSetOnTimeModalVisible, setShowSetOnTimeModalVisible] = useState(false);
  const [showPinChangeModalVisible, setShowPinChangeModalVisible] = useState(false);
  const [changeNameDialogVisible, setChangeNameDialogVisible] = useState(false);

  const { theme } = useTheme();

  const {
    params_device,
    device,
    upTime,
    permission,
    noStateDevice,
    params_devices,
    group_params,
    deviceInScene,
    removeDeviceFromScene,
    changeSceneFromDevice,
    activeSceneControl,
  } = props.route.params.props;

  const state = props.deviceState;
  const [deviceParams, setDeviceParams] = useState(params_device?.get("params"));
  const [changeName, setChangeName] = useState(device.get("opis_menu"));

  const handleChangeNameOK = (key) => {
    setChangeNameDialogVisible(false);
    props.onChangeName(device.get("id"), changeName, `devices`);
    mqttClient.changeName(device.get("id"), changeName, `device`);
  };

  let userNote = "";
  try {
    userNote = device && device.get("data") ? JSON.parse(device.get("data")).userNote : "";
  } catch (e) {}

  const optionsFull = {
    removeFromFavouritesOption: {
      title: t("REMOVE_FROM_FAVOURITES"),
      iconName: "user-minus",
      action: () => {
        props.removeDeviceFromFavourites(device.get("id"));
      },
    },
    addToFavouritesOption: {
      title: t("ADD_TO_FAVOURITES"),
      iconName: "user-plus",
      action: () => {
        props.addDeviceToFavourites(device.get("id"));
      },
    },
    changeNameOption: {
      title: t("CHANGE_NAME"),
      iconName: "edit-2",
      action: () => {
        setChangeNameDialogVisible(true);
      },
    },
    showPinChangeOption: {
      title: t("CHANGE_PASSWORD"),
      iconName: "key",
      action: () => {
        setShowPinChangeModalVisible(true);
      },
    },

    setOnTime: {
      title: t("SET_ON_TIME"),
      iconName: "clock",
      action: () => {
        setShowSetOnTimeModalVisible(true);
      },
    },
    changeToHeating: {
      title: t("CHANGE_TO_HEATING"),
      iconName: "sun",
      action: () => {
        mqttClient.setHeatingType(device.get("id"), 0);
      },
    },
    changeToCooling: {
      title: t("CHANGE_TO_COOLING"),
      iconName: "thermometer",
      action: () => {
        mqttClient.setHeatingType(device.get("id"), 1);
      },
    },
    heatingSchedule: {
      title: t("SCHEDULE"),
      iconName: "calendar",
      action: () => {
        props.navigation.navigate("HeatingScreen", {
          placeName: "HEATING",
          device: device,
        });

        //navigation.navigate(`${props.screenName}`, props.params);
      },
    },
    removeFromScenes: {
      title: t("scenes:REMOVE_DEVICE"),
      iconName: "trash-2",
      action: () => {
        removeDeviceFromScene(deviceInScene.get("temporary_id"));
        props.navigation.goBack();
      },
    },
    chart: {
      title: t("SHOW_CHART"),
      iconName: "bar-chart",
      action: () => {
        props.navigation.navigate("ChartScreen", {
          placeName: "CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
      },
    },
    usageChart: {
      title: t("SHOW_BAR_CHART"),
      iconName: "bar-chart-2",
      action: () => {
        props.navigation.navigate("ChartScreen", {
          placeName: "BAR_CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
      },
    },
    removeFromActiveOption: {
      title: t("active:REMOVE_DEVICE"),
      iconName: "trash",
      action: () => {
        mqttClient.removeDeviceFromActive(device.get("id"));
        let newParam = deviceParams;
        newParam = newParam &= ~0b1;
        setDeviceParams(newParam);
      },
    },
    addToActiveOption: {
      title: t("active:ADD_DEVICE"),
      iconName: "plus-circle",
      action: () => {
        mqttClient.addDeviceToActive(device.get("id"));
        let newParam = deviceParams;
        newParam = newParam | 0b1;
        setDeviceParams(newParam);
      },
    },
    enableFlux: {
      title: t("ENABLE_FLUX"),
      iconName: "zap",
      action: () => {
        mqttClient.addDeviceToFluxSimulation(device.get("id"));
        let newParam = deviceParams;
        newParam = newParam | 0x8000000;
        setDeviceParams(newParam);
      },
    },
    disableFlux: {
      title: t("DISABLE_FLUX"),
      iconName: "zap-off",
      action: () => {
        mqttClient.removeDeviceFromFluxSimulation(device.get("id"));

        let newParam = deviceParams;
        newParam = newParam &= ~0x8000000;
        setDeviceParams(newParam);
      },
    },
    setFlux: {
      title: t("SET_FLUX"),
      iconName: "sliders",
      action: () => {
        const message = `/api/set/${device.get("id")}/setFlux`;
        mqttClient.stateChangeToSend(message, device.get("id"));
      },
    },

    showFullWidth: {
      title: t("account:SHOW_FULL_WIDTH"),
      iconName: "align-justify",
      action: () => {
        props.changeObjectSize(device.get("id"), "fullWidth");
      },
    },
    showColumnWidth: {
      title: t("account:SHOW_COLUMN_WIDTH"),
      iconName: "crop",
      action: () => {
        props.changeObjectSize(device.get("id"), "defaultWidth");
      },
    },
  };

  // let changeWidthText = customWidth ? t("account:SHOW_COLUMN_WIDTH") : t("account:SHOW_FULL_WIDTH");

  const declareOptions = () => {
    let routeName = props.route.params.routeName;
    let permissionForActives = (permission & 0x04) == 0;
    let option1 = params_device ? params_device.get("params") & 0x8000 : false;
    let options = [];

    let permissionForEditName = params_device ? (params_device.get("params") & 0x40) === 0 : false;

    if (permissionForEditName) {
      options.push("changeNameOption");
    }
    if (props.favouritesDevices && props.favouritesDevices.includes(device.get("id"))) {
      options.push("removeFromFavouritesOption");
    } else {
      options.push("addToFavouritesOption");
    }

    if (routeName === "SceneDetails") {
      options.push("removeFromScenes");
    }
    if (["led", "przekaznik", "flaga"].includes(device.get("typ_komponentu"))) {
      options.push("setOnTime");
    }

    // if (["led", "przekaznik", "flaga", "rgb", "rgbw", "ledww", "rgbww", "wej", "ip_radio", "radio"].includes(device.get("typ_komponentu"))) {
    //   if (upTime != null && upTime != undefined) {
    //     options.push("showInfoOption");
    //   }
    // }

    if (!["roleta", "roleta_procenty", "roleta_lamelki"].includes(device.get("typ_komponentu"))) {
      if (device.get("czas_logowania") >= 0) {
        options.push("chart");

        if (
          device.get("typ_komponentu") !== "temp" &&
          device.get("typ_komponentu") !== "reg" &&
          device.get("typ_komponentu") !== "roleta_lamelki" &&
          device.get("typ_komponentu") !== "roleta_procent" &&
          device.get("typ_komponentu") !== "roleta" &&
          device.get("typ_komponentu") !== "rgb" &&
          device.get("typ_komponentu") !== "rgbw" &&
          device.get("typ_komponentu") !== "rgbww"
        ) {
          options.push("usageChart");
        }
      }
      if (device.get("bezpieczne") || (device.get("typ_komponentu") == "satel_alarm" && option1)) {
        options.push("showPinChangeOption");
      }

      if (device.get("typ_komponentu") == "reg") {
        options.push("heatingSchedule");
      }

      if (state && state.get("cooling") && device.get("typ_komponentu") == "reg" && deviceParams !== undefined && !(deviceParams & 0x8000)) {
        if (state.get("cooling") == 0) {
          options.push("changeToCooling");
        } else if (state.get("cooling") == 1) {
          options.push("changeToHeating");
        }
      }
    }
    if (routeName == "ActiveScreen" && permissionForActives) {
      options.push("removeFromActiveOption");
    }
    if (permissionForActives && deviceParams !== undefined && (deviceParams & 0x1) == 0) {
      options.push("addToActiveOption");
    }

    if (device.get("typ_komponentu") == "ledww") {
      if (deviceParams !== undefined && device) {
        if (device.get("typ_komponentu") == "ledww" && (deviceParams & 0x8000000) == 0) {
          options.push("enableFlux");
        }
        if (device.get("typ_komponentu") == "ledww" && (deviceParams & 0x8000000) > 0) {
          options.push("disableFlux");
        }
        options.push("setFlux");
      }
    }
    if (!typesShowOnFullWith.includes(device.get("typ_komponentu"))) {
      if (props.objectsWtihCustomSize && props.objectsWtihCustomSize.get(device.get("id").toString()) === "fullWidth") {
        options.push("showColumnWidth");
      } else {
        options.push("showFullWidth");
      }
    }

    const objectWithOptions = options.map((el) => {
      return optionsFull[el];
    });

    setObjectOptions(objectWithOptions);
    //"setState({ options });
  };

  useEffect(() => {
    declareOptions();
  }, [state, props.favouritesDevices, deviceParams, props.objectsWtihCustomSize]);

  useEffect(() => {
    if (componentToShowHistory.includes(device.get("typ_komponentu")) && props.deviceState && props.deviceState.get("state") !== undefined) {
      let message = `/api/json/logbook/4/0/0/0/${device.get("id")}`;
      mqttClient.askForLogbook(message, device.get("id"));
    }
  }, []);

  const logbookData = state && state.get("logbookData") ? state.get("logbookData").get("List") : null;

  return (
    <ScreenView>
      {showSetOnTimeModalVisible && (
        <DeviceTimeControlDialog
          device={device}
          dialogVisible={showSetOnTimeModalVisible}
          handleCloseDialog={() => {
            setShowSetOnTimeModalVisible(false);
          }}
        />
      )}
      {showPinChangeModalVisible && (
        <DeviceChangePinDialog
          device={device}
          params_device={params_device}
          dialogVisible={showPinChangeModalVisible}
          handleCloseDialog={() => {
            setShowPinChangeModalVisible(false);
          }}
        />
      )}
      {changeNameDialogVisible && (
        <ModalSimple
          isVisible={changeNameDialogVisible}
          title={"CHANGE_NAME"}
          onInputChange={setChangeName}
          onClose={() => {
            setChangeNameDialogVisible(false);
          }}
          onOK={handleChangeNameOK}
          value={changeName}
        />
      )}

      <View style={{ flexDirection: "column", flex: 1 }}>
        <DeviceListHandler
          device={device}
          detailContent={true}
          deviceInScene={deviceInScene}
          changeSceneFromDevice={changeSceneFromDevice}
          activeSceneControl={activeSceneControl}
        />
        {state && state.get("on") && !componentsToHideLastChange.includes(device.get("typ_komponentu")) && (
          <ComponentContainer numOfColumns={1}>
            <View flexDirection="row" justifyContent={"space-between"} style={{ height: 30, marginLeft: 10 }} alignItems="center">
              <ComponentHeaderText tickerDisabled={true}>{t("CHANGED_ON")}</ComponentHeaderText>
              <SecondaryColorText>
                {formatDistanceToNow(new Date(state.get("on")), {
                  addSuffix: true,
                  includeSeconds: true,
                  locale: locales[lang] || locales.enUS,
                })}
              </SecondaryColorText>
            </View>
          </ComponentContainer>
        )}
        {logbookData && <DeviceHistory logbookData={logbookData} device={device} />}

        {userNote && (
          <ComponentContainer numOfColumns={1}>
            <ComponentHeaderText tickerDisabled={true}>{t("NOTE") + ":"}</ComponentHeaderText>
            <PrimaryColorText alignItems="center" justifyContent={"center"}>
              {userNote}
            </PrimaryColorText>
          </ComponentContainer>
        )}

        <ComponentContainer numOfColumns={1}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View flexDirection="row" justifyContent={"space-between"} tyle={{ marginBottom: 10 }} alignItems="center">
              <ButtonsSelector tabs={objectOptions} fontSize={12} />
            </View>
          </View>
        </ComponentContainer>
      </View>
    </ScreenView>
  );
};

const styles = {
  colorContainer: {
    display: "flex",
    height: 50,
    width: 50,
    borderRadius: 50,
    borderColor: "gray",
    borderWidth: 1,
  },
  empty: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  withColor: {
    backgroundColor: "red",
  },
};

const mapStateToProps = (state, ownProps) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    deviceState: state.statesData.get("states").get(ownProps.route.params.device.get("id")),
    favouritesDevices: state.profilesSettings.get(currentProfile).get("favouritesDevices"),
    dimensions: state.statesData.get("dimensions"),
    objectsWtihCustomSize: state.profilesSettings.get(currentProfile).get("objectsWtihCustomSize"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDeviceToFavourites: (deviceID) => dispatch(addDeviceToFavourites(deviceID)),
    removeDeviceFromFavourites: (deviceID) => dispatch(removeDeviceFromFavourites(deviceID)),
    onChangeParamsOfDevice: (deviceID, params) => dispatch(changeParamsOfDevice(deviceID, params)),
    onChangeName: (objectID, newName, objectType) => dispatch(changeName(objectID, newName, objectType)),
    changeObjectSize: (deviceID, type) => dispatch(changeObjectSize(deviceID, type)),
    // onChangeWidth: (newWidth, key) => dispatch(changeWidth(newWidth, key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsScreen);
