import * as React from "react";
import { Text, View, TouchableOpacity, Platform } from "react-native";
import { t } from "../services/i18n";
import ActionSheet from "./ActionSheet";
import { connect, shallowEqual } from "react-redux";
import mqttClient from "../services/mqtt";
import { useNavigation, useRoute } from "@react-navigation/native";

import { ComponentHeaderText, PrimaryColorText, SecondaryColorText, StyledIcon, TextInputStyled } from "../components/UI/styledComponents";
import { addDeviceToFavourites, removeDeviceFromFavourites, changeParamsOfDevice } from "../store/actions";
import DeviceInfoDialog from "./DeviceInfoDialog";
import DeviceChangePinDialog from "./DeviceChangePinDialog";
import DeviceTimeControlDialog from "./DeviceTimeControlDialog";
import { store } from "../store/configureStore";
import * as Localization from "expo-localization";
import { format, formatDistance, formatDistanceToNow, formatRelative, subDays } from "date-fns";
import { ar, cs, de, es, fr, it, pl, pt } from "date-fns/locale";
import { devicesWithoutModal } from "./smartHomeHandlers/DeviceListHandler";
const locales = { ar, cs, de, es, fr, it, pl, pt };
//import humanizeDuration from "humanize-duration";
let lang = Localization.locale.split("-")[0];

const skipObjectsToShowActivity = [
  "wykres",
  "ir",
  "ircode",
  "kontrola_dostepu",
  "event",
  "grupa",
  "custom",
  "energy",
  "separator",
  "webview",
  "kamera_mjpeg",
  "kamera_rtsp",
  "sip",
];

class HeaderWithDotsDevicesX extends React.Component {
  constructor(props) {
    super(props);

    this.removeFromFavouritesOption = t("REMOVE_FROM_FAVOURITES");
    this.addToFavouritesOption = t("ADD_TO_FAVOURITES");
    this.showPinChangeOption = t("CHANGE_PASSWORD");
    this.showInfoOption = t("SHOW_INFO");
    this.setOnTime = t("SET_ON_TIME");
    this.changeToHeating = t("CHANGE_TO_HEATING");
    this.changeToCooling = t("CHANGE_TO_COOLING");
    this.removeFromScenes = t("scenes:REMOVE_DEVICE");
    this.chart = t("SHOW_CHART");
    this.usageChart = t("SHOW_BAR_CHART");
    this.removeFromActiveOption = t("active:REMOVE_DEVICE");
    this.addToActiveOption = t("active:ADD_DEVICE");
    this.enableFlux = t("ENABLE_FLUX");
    this.disableFlux = t("DISABLE_FLUX");
    this.setFlux = t("SET_FLUX");

    this.state = {
      showInfoModalVisible: false,
      showPinChangeModalVisible: false,
      showSetOnTimeModalVisible: false,
      deviceName: props.device.get("opis_menu"),
      showWebActionSheet: false,
      formattedTime: "",
    };

    this.options = [];
  }

  componentDidMount() {
    this.declareOptions();
    this.updateTime(this.props.state);
    this.intervalId = setInterval(() => {
      this.updateTime(this.props.state);
    }, 60000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.intervalId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { state } = this.props;
    if (state && nextProps.state && state.get("on") && nextProps.state.get("on") && state.get("on") != nextProps.state.get("on")) {
      this.updateTime(nextProps.state);
    }
  }

  updateTime = (state) => {
    let { device } = this.props;
    if (state && state.get("on") && !skipObjectsToShowActivity.includes(device.get("typ_komponentu"))) {
      this.setState({
        formattedTime: formatDistanceToNow(new Date(state.get("on")), {
          addSuffix: true,
          includeSeconds: true,
          locale: locales[lang] || locales.enUS,
        }),
      });
    }
  };

  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  doAction = (index) => {
    let { device, params_device, deviceInScene } = this.props;
    switch (this.options[index]) {
      case this.showPinChangeOption: {
        this.setState({ showPinChangeModalVisible: true });
        break;
      }
      case this.showInfoOption: {
        this.setState({ showInfoModalVisible: true });
        break;
      }
      case this.setOnTime: {
        this.setState({ showSetOnTimeModalVisible: true });
        break;
      }
      case this.removeFromFavouritesOption: {
        this.props.onRemoveDeviceFromFavourites(device.get("id"));
        break;
      }
      case this.addToFavouritesOption: {
        this.props.onAddDeviceToFavourites(device.get("id"));
        break;
      }
      case this.removeFromScenes: {
        this.props.removeDeviceFromScene(deviceInScene.get("temporary_id"));
        break;
      }
      case this.chart: {
        this.props.navigation.navigate("ChartScreen", {
          placeName: "CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
        break;
      }
      case this.usageChart: {
        this.props.navigation.navigate("ChartScreen", {
          placeName: "BAR_CHART",
          deviceID: device.get("id"),
          deviceName: device.get("opis_menu"),
          deviceType: device.get("typ_komponentu"),
        });
        break;
      }

      case this.changeToHeating:
        {
          mqttClient.setHeatingType(device.get("id"), 0);
        }
        break;

      case this.changeToCooling:
        {
          mqttClient.setHeatingType(device.get("id"), 1);
        }
        break;

      case this.removeFromActiveOption: {
        mqttClient.removeDeviceFromActive(device.get("id"));
        let newParam = params_device.get("params");
        newParam &= ~0b1;
        // #define AKT_DEV_MASK 0x01
        // newParam = newParam & ~(PARAM_AKT_DEV_MASK);
        let newParams = params_device.set("params", newParam);
        this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.addToActiveOption: {
        mqttClient.addDeviceToActive(device.get("id"));
        let newParam = params_device.get("params");
        newParam = newParam | 0b1;
        let newParams = params_device.set("params", newParam);
        this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.enableFlux: {
        mqttClient.addDeviceToFluxSimulation(device.get("id"));
        // let newParam = params_device.get("params");
        // newParam = newParam | 0x8000000;
        // let newParams = params_device.set("params", newParam);
        // this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.disableFlux: {
        mqttClient.removeDeviceFromFluxSimulation(device.get("id"));
        // let newParam = params_device.get("params");
        // newParam &= ~0x8000000;
        // let newParams = params_device.set("params", newParam);
        // this.props.onChangeParamsOfDevice(device.get("id"), newParams);
        break;
      }
      case this.setFlux: {
        const message = `/api/set/${device.get("id")}/setFlux`;
        mqttClient.stateChangeToSend(message, device.get("id"));
        break;
      }
    }
  };
  handleOnPressAction = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false }, () => {
        this.doAction(index);
      });
    } else {
      this.doAction(index);
    }
  };

  declareOptions = () => {
    let { params_device, device, upTime, state, permission, route, noStateDevice, params_devices, hideLastChange } = this.props;
    let routeName = route.name;
    let permissionForActives = (permission & 0x04) == 0;
    let option1 = params_device ? params_device.get("params") & 0x8000 : false;
    let options = [];

    if (routeName === "FavouritesScreen") {
      options.push(this.removeFromFavouritesOption);
    } else {
      if (!noStateDevice) {
        options.push(this.addToFavouritesOption);
      }
    }
    if (routeName === "SceneDetails") {
      options.push(this.removeFromScenes);
    }
    if (["led", "przekaznik", "flaga"].includes(device.get("typ_komponentu"))) {
      options.push(this.setOnTime);
    }

    if (["led", "przekaznik", "flaga", "rgb", "rgbw", "ledww", "rgbww", "wej", "ip_radio", "radio"].includes(device.get("typ_komponentu"))) {
      if (upTime != null && upTime != undefined) {
        options.push(this.showInfoOption);
      }
    }

    if (!["roleta", "roleta_procenty", "roleta_lamelki"].includes(device.get("typ_komponentu"))) {
      if (device.get("czas_logowania") >= 0) {
        options.push(this.chart);

        if (
          device.get("typ_komponentu") !== "temp" &&
          device.get("typ_komponentu") !== "reg" &&
          device.get("typ_komponentu") !== "roleta_lamelki" &&
          device.get("typ_komponentu") !== "roleta_procent" &&
          device.get("typ_komponentu") !== "roleta" &&
          device.get("typ_komponentu") !== "rgb" &&
          device.get("typ_komponentu") !== "rgbw" &&
          device.get("typ_komponentu") !== "rgbww"
        ) {
          options.push(this.usageChart);
        }
      }
      if (device.get("bezpieczne") || (device.get("typ_komponentu") == "satel_alarm" && option1)) {
        options.push(this.showPinChangeOption);
      }
      if (state && state.get("cooling") && device.get("typ_komponentu") == "reg" && params_device && !(params_device.get("params") & 0x8000)) {
        if (state.get("cooling") == 0) {
          options.push(this.changeToCooling);
        } else if (state.get("cooling") == 1) {
          options.push(this.changeToHeating);
        }
      }
    }
    if (routeName == "ActiveScreen" && permissionForActives) {
      options.push(this.removeFromActiveOption);
    }
    if (permissionForActives && params_device && (params_device.get("params") & 0x1) == 0) {
      options.push(this.addToActiveOption);
    }
    if (device.get("typ_komponentu") == "ledww") {
      if (params_devices && device) {
        const params_device2 = params_devices.get(device.get("id"));
        if (device.get("typ_komponentu") == "ledww" && params_device2 && (params_device2.get("params") & 0x8000000) == 0) {
          options.push(this.enableFlux);
        }
        if (device.get("typ_komponentu") == "ledww" && params_device2 && (params_device2.get("params") & 0x8000000) > 0) {
          options.push(this.disableFlux);
        }
        options.push(this.setFlux);
      }
    }

    this.options = options;
    return options;
    //this.setState({ options });
  };

  render() {
    let { showInfoModalVisible, showPinChangeModalVisible, deviceName, showSetOnTimeModalVisible } = this.state;

    let {
      device,
      params_device,
      deviceFromCustom,
      noStateDevice,
      upTime,
      deviceIdShown,
      deviceNameToShow,
      deviceInScene,
      deviceFromAutomatization,
      route,
      state,
      hideLastChange,
    } = this.props;
    let options = this.declareOptions();

    let headerHidden = params_device && (params_device.get("params") & 0x4000) > 0;
    let threeDotsHidden = params_device && (params_device.get("params") & 0x1000000) > 0;

    if (this.props.dimensions.get("smallTiles") && !devicesWithoutModal.includes(device.get("typ_komponentu"))) {
      threeDotsHidden = true;
    }

    let threeDotsDisable = false;
    if ((noStateDevice && !deviceInScene) || threeDotsHidden) {
      threeDotsDisable = true;
    }
    if (deviceInScene || deviceFromAutomatization) {
      headerHidden = false;
    }
    if (route && (route.name == "FavouritesScreen" || route.name == "SceneDetails")) {
      headerHidden = false;
      if (!this.props.dimensions.get("smallTiles")) {
        threeDotsDisable = false;
      }
    }

    const deviceFromCustomComponent = !headerHidden && (
      <TouchableOpacity
        onPress={this.showActionSheet}
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 5,
          flex: 1,
        }}
        disabled={threeDotsDisable}
      >
        {!threeDotsDisable && <StyledIcon color="secondary" size={14} name="more-vertical" />}
        <ComponentHeaderText
          numberOfLines={1}
          bold={true}
          style={{
            marginLeft: -2,
            fontSize: 14,
            textAlign: "center",
          }}
        >
          {deviceNameToShow}
          {deviceIdShown && ` (${device.get("id")})`}
        </ComponentHeaderText>
      </TouchableOpacity>
    );

    //const timeElapsed = state && state.get("on") ? new Date().getTime() - state.get("on") : 0;

    const deviceNotFromCustomComponent = !headerHidden ? (
      <View
        style={{
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flexDirection: !threeDotsDisable ? "row" : undefined,
            alignItems: !threeDotsDisable ? "center" : undefined,
          }}
        >
          {!threeDotsDisable && (
            <TouchableOpacity onPress={this.showActionSheet}>
              <StyledIcon color="secondary" size={20} name="more-vertical" />
            </TouchableOpacity>
          )}

          <View
            style={{
              paddingLeft: threeDotsDisable ? 8 : null,
            }}
          >
            <ComponentHeaderText numberOfLines={1}>
              {deviceNameToShow}
              {deviceIdShown && ` (${device.get("id")})`}
            </ComponentHeaderText>
          </View>
        </View>

        {!hideLastChange && this.state.formattedTime && !this.props.dimensions.get("smallTiles") ? (
          <SecondaryColorText style={{ marginLeft: 5 }}>{this.state.formattedTime}</SecondaryColorText>
        ) : null}
      </View>
    ) : null;

    const deviceFromAutomatizationComponent = (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PrimaryColorText bold>{t("DEVICE")}: </PrimaryColorText>

        <PrimaryColorText numberOfLines={1}>
          {deviceNameToShow}
          {deviceIdShown && ` (${device.get("id")})`}
        </PrimaryColorText>
      </View>
    );

    return (
      <View
        style={{
          justifyContent: "center",
          width: deviceFromCustom ? "100%" : null,
          marginRight: deviceFromCustom || this.props.dimensions.get("smallTiles") ? null : 10,
          paddingRight: deviceFromCustom || this.props.dimensions.get("smallTiles") ? null : 10,
          flex: 1,
        }}
      >
        {!threeDotsDisable && (
          <ActionSheet
            key={options.flatMap((el) => el)}
            actionRef={(o) => (this.ActionSheet = o)}
            options={options ? [...options, t("CLOSE")] : ["CLOSE"]}
            cancelButtonIndex={options ? options.length : 0}
            onPress={(index) => {
              this.handleOnPressAction(index);
            }}
            onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
            showWebActionSheet={this.state.showWebActionSheet}
          />
        )}

        {showInfoModalVisible && (
          <DeviceInfoDialog
            deviceID={device.get("id")}
            dialogVisible={showInfoModalVisible}
            upTime={upTime}
            deviceType={device.get("typ_komponentu")}
            handleCloseDialog={() => {
              this.setState({ showInfoModalVisible: false });
            }}
          />
        )}
        {showPinChangeModalVisible && (
          <DeviceChangePinDialog
            device={device}
            params_device={params_device}
            dialogVisible={showPinChangeModalVisible}
            handleCloseDialog={() => {
              this.setState({ showPinChangeModalVisible: false });
            }}
          />
        )}

        {showSetOnTimeModalVisible && (
          <DeviceTimeControlDialog
            device={device}
            dialogVisible={showSetOnTimeModalVisible}
            handleCloseDialog={() => {
              this.setState({ showSetOnTimeModalVisible: false });
            }}
          />
        )}
        {deviceFromAutomatization ? deviceFromAutomatizationComponent : !deviceFromCustom ? deviceNotFromCustomComponent : deviceFromCustomComponent}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceIdShown: state.globalSettings.get("deviceIdShown"),
    hideLastChange: state.globalSettings.get("hideLastChangeTimeOnList"),
    dimensions: state.statesData.get("dimensions"),
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeviceToFavourites: (deviceID) => dispatch(addDeviceToFavourites(deviceID)),
    onRemoveDeviceFromFavourites: (deviceID) => dispatch(removeDeviceFromFavourites(deviceID)),
    onChangeParamsOfDevice: (deviceID, params) => dispatch(changeParamsOfDevice(deviceID, params)),
  };
};

const HeaderWithDotsDevices = (props) => {
  const navigation = useNavigation();
  const route = useRoute();

  return <HeaderWithDotsDevicesX {...props} navigation={navigation} route={route} />;
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithDotsDevices);
